<template>
  <main :class="['home-page', {'loaded': loaded}, {'pointerNone': pointerNone} ]">
    <div :class="['bg-video', {'active': centerSectionIsVisible}]">
      <video loop autoplay muted>
        <source src="./../assets/video/hero.mp4" type="video/mp4">
      </video>
      <video class="mob" loop autoplay muted playsinline>
        <source src="./../assets/video/hero-mob.mp4" type="video/mp4">
      </video>
    </div>

    <section class="section main-section">
      <div :class="['bg-list', {'active': bottomSectionIsVisible}]" v-if="$refs.slick">
        <img src="./../assets/img/shots/bg1.jpeg" :class="['bg', {'active': $refs.slick.currentSlide() + 1 == 1}]">
        <img src="./../assets/img/shots/bg2.jpeg" :class="['bg', {'active': $refs.slick.currentSlide() + 1 == 2}]">
        <img src="./../assets/img/shots/bg3.jpeg" :class="['bg', {'active': $refs.slick.currentSlide() + 1 == 3}]">
        <img src="./../assets/img/shots/bg4.jpeg" :class="['bg', {'active': $refs.slick.currentSlide() + 1 == 4}]">
        <img src="./../assets/img/shots/bg5.jpeg" :class="['bg', {'active': $refs.slick.currentSlide() + 1 == 5}]">
        <img src="./../assets/img/shots/bg6.jpeg" :class="['bg', {'active': $refs.slick.currentSlide() + 1 == 6}]">
      </div>
      <div :class="['home-text up-text last-text', {'active': bottomSectionIsVisible}]">
        <div class="desc">
          We transform simple ideas into powerful brands and futuristic products that set a benchmark for exceptional design and user experience.
        </div>
        <a target="_blank" href="https://calendly.com/nkd-studio/quick-sync?back=1&month=2024-11" class="button">Book a Call</a>
      </div>
      <div :class="['home-text up-text last-text socials', {'active': bottomSectionIsVisible}]">
        <a href="https://x.com/get_nkd" target="_blank">
          <img src="./../assets/img/x.svg" class="img">
        </a>
        <a href="#" target="_blank">
          <img src="./../assets/img/d.svg" class="img">
        </a>
      </div>
      <div :class="['home-text center-text', {'hidden': upSectionIsVisible || bottomSectionIsVisible || !centerTextIsVisible}]">
        <div class="desc">
          We transform simple ideas into powerful brands and futuristic products that set a benchmark for exceptional design, making a real difference in the world.
        </div>
        <br/><br/>
        <div class="desc dark">Press &#8593; to read more..</div>
        <button class="button">Book a Call <span class="symbol">&#8629;</span></button>
      </div>
      <div :class="['home-text up-text last-text', {'active': lastSectionIsVisible}]">
        <div class="desc">
          Every great brand begins with a powerful vision, sparked by a simple idea—one that can shape the world.
          <br/><br/>
          At Naked, we’re here to nurture those ideas, transforming them into influential brands and sleek, forward-thinking product solutions.
          <br/><br/>
          While we welcome all collaborations, we’re especially drawn to teams with bold visions and a commitment to making a meaningful impact.
        </div>
        <button class="button">Book a Call <span class="symbol">&#8629;</span></button>
      </div>
      <div :class="['arrows', {'upSectionIsVisible': upSectionIsVisible}, {'bottomSectionIsVisible': bottomSectionIsVisible}, {'lastSectionIsVisible': lastSectionIsVisible}]">
        <button :class="['button up', {'active': bottomSectionIsVisible}]" @click="pageUp()">
          <img src="./../assets/img/up.svg" class="img">
        </button>
        <button :class="['button down', {'active': centerSectionIsVisible}]" @click="pageDown()">
          <img src="./../assets/img/down.svg" class="img">
        </button>
      </div>
      <div :class="['esc', {'active': bottomSectionIsVisible || lastSectionIsVisible}]" @click="fromBottomToCenterRightAway">
        <button class="button">Esc</button>
      </div>
      <div :class="['bar', {'active': bottomSectionIsVisible}]" v-if="totalSlides">
        <div class="bar-item" :style="[{height: 'calc(100% / ' + totalSlides + ')'}, {top: 'calc((100% / ' + totalSlides + ') * ' + ($refs.slick.currentSlide()) + ')'}]"></div>
      </div>
      <div :class="['carousel', {'slide-down': upSectionIsVisible}, {'full': bottomSectionIsVisible || carouselIsFull}, {'slide-up': !carouselIsVisible}, {'hidden': carouselIsHidden},]">
        <slick 
          ref="slick"  
          :options="slickOptions" 
          @init="onInit"
          class="slider"
        >
          <div :class="['item', {'slide-down': !slideIsHiddenDown1}, {'slide-down-fully': !slideIsHiddenDown1Fully}, {'slide-up': !slideIsHiddenUp1}]">
            <div class="item-wrapper">
              <img src="./../assets/img/shots/1.jpg" class="img">
              <div class="desc">
                <span>
                  We collaborated with NoRamp to develop a brand vision and a design language for their new product “Wands”
                </span>
              </div>
            </div>
          </div>
          <div :class="['item', {'slide-down': !slideIsHiddenDown2}, {'slide-down-fully': !slideIsHiddenDown2Fully}, {'slide-up': !slideIsHiddenUp2}]">
            <div class="item-wrapper">
              <img src="./../assets/img/shots/2.jpg" class="img">
              <div class="desc">
                <span>
                  2 We collaborated with NoRamp to develop a brand vision and a design language for their new product “Wands”
                </span>
              </div>
            </div>
          </div>
          <div :class="['item', {'slide-down': !slideIsHiddenDown3}, {'slide-down-fully': !slideIsHiddenDown3Fully}, {'slide-up': !slideIsHiddenUp3}]">
            <div class="item-wrapper">
              <img src="./../assets/img/shots/3.jpg" class="img">
              <div class="desc">
                <span>
                  3 We collaborated with NoRamp to develop a brand vision and a design language for their new product “Wands”
                </span>
              </div>
            </div>
          </div>
          <div :class="['item', {'slide-down': !slideIsHiddenDown4}, {'slide-down-fully': !slideIsHiddenDown4Fully}, {'slide-up': !slideIsHiddenUp4}]">
            <div class="item-wrapper">
              <img src="./../assets/img/shots/4.jpg" class="img">
              <div class="desc">
                <span>
                  4 We collaborated with NoRamp to develop a brand vision and a design language for their new product “Wands”
                </span>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="item-wrapper">
              <img src="./../assets/img/shots/5.jpg" class="img">
              <div class="desc">
                <span>
                 5 We collaborated with NoRamp to develop a brand vision and a design language for their new product “Wands”
                </span>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="item-wrapper">
              <img src="./../assets/img/shots/6.jpg" class="img">
              <div class="desc">
                <span>
                 6 We collaborated with NoRamp to develop a brand vision and a design language for their new product “Wands”
                </span>
              </div>
            </div>
          </div>
        </slick>
      </div>
    </section>
  </main>
</template>
<script>
import Slick from 'vue-slick';

export default {
  name: 'Home',
  props: [],
  components: {
    Slick
  },
  data: function() {
    return {
      centerSectionIsVisible: true,
      upSectionIsVisible: false,
      bottomSectionIsVisible: false,
      lastSectionIsVisible: false,
      bottomTextIsVisible: false,
      carouselIsVisible: true,
      centerTextIsVisible: true,
      shotIsVisible: false,
      slideIsHiddenDown1: true,
      slideIsHiddenDown2: true,
      slideIsHiddenDown3: true,
      slideIsHiddenDown4: true,
      slideIsHiddenUp1: true,
      slideIsHiddenUp2: true,
      slideIsHiddenUp3: true,
      slideIsHiddenUp4: true,
      slideIsHiddenDown1Fully: false,
      slideIsHiddenDown2Fully: false,
      slideIsHiddenDown3Fully: false,
      slideIsHiddenDown4Fully: false,
      activeShotDesc: '',
      loaded: false,
      carouselIsFull: false,
      carouselIsHidden: false,

      slickOptions: {
        speed: 700,
        arrows: false,
        infinite: true,
        dots: false,
        variableWidth: true,
        swipe: false,
        accessibility: false
      },
      totalSlides: 0,

      pointerNone: false
    }
  },
  mounted() {
    let self = this;
    setTimeout(function() {
      self.loaded = true;
      self.slideIsHiddenDown1Fully = true;
    }, 400)
    setTimeout(function() {
      self.slideIsHiddenDown2Fully = true;
    }, 500)
    setTimeout(function() {
      self.slideIsHiddenDown3Fully = true;
    }, 600)
    setTimeout(function() {
      self.slideIsHiddenDown4Fully = true;
    }, 700)
    global.jQuery('.slider').on('beforeChange', function(event, slick, currentSlide, nextSlide) {
      global.jQuery('.slider .slick-slide').removeClass('previous-slide');
      for (let i = 0; i < nextSlide; i++) {
        global.jQuery(`.slider .slick-slide[data-slick-index="${i}"]`).addClass('previous-slide');
      }
    });
    window.addEventListener("keydown", this.handleKeydown);
    window.addEventListener("wheel", this.checkScroll);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.handleKeydown);
    window.removeEventListener("wheel", this.checkScroll);
  },
  methods: {
    checkScroll(event) {
      let self = this;
      if (event.deltaY > 0) {
        if (!this.pointerNone) {
          this.pageDown();
        }
        this.pointerNone = true;
        setTimeout(() => {
          self.pointerNone = false;
        }, 700);
      } else {
        if (!this.pointerNone) {
          this.pageUp();
        }
        this.pointerNone = true;
        setTimeout(() => {
          self.pointerNone = false;
        }, 700);
      }
    },
    onInit(event, slick) {
      this.totalSlides = slick.slideCount;
    },
    handleKeydown(event) {
    
      if (event.key === "ArrowUp") {
        if (!this.pointerNone) {
          this.pageUp();
        }
      }
      if (event.key === "ArrowDown") {
        if (!this.pointerNone) {
          this.pageDown();
        }
      }
      // if (event.key === "Escape") {
      //   this.fromBottomToCenterRightAway();
      // }
    },
    next() {
      let self = this;
      this.pointerNone = true;
      setTimeout(() => {
        self.pointerNone = false;
      }, 700);
      
      this.$refs.slick.next();
    },
    prev() {
      let self = this;
      this.pointerNone = true;
      setTimeout(() => {
        self.pointerNone = false;
      }, 700);

      this.$refs.slick.prev();
    },
    fromCenterToTop() {
      let self = this;
      self.$refs.slick.goTo(0);
      self.slideIsHiddenDown1 = false;
      setTimeout(function() {
        self.slideIsHiddenDown2 = false;
      }, 100)
      setTimeout(function() {
        self.slideIsHiddenDown3 = false;
      }, 200)
      setTimeout(function() {
        self.slideIsHiddenDown4 = false;
      }, 300)
      setTimeout(function() {
        self.upSectionIsVisible = true;
        self.centerSectionIsVisible = false;
      }, 600)
    },
    fromLastToBottom(num) {
      let self = this;
      if (num != 0) {
        self.carouselIsHidden = true;
        setTimeout(function() {
          self.lastSectionIsVisible = false;
        }, 300)
        setTimeout(function() {
          self.bottomSectionIsVisible = true;
          self.carouselIsVisible = true;
          self.$refs.slick.goTo(num);
        }, 800)
        setTimeout(function() {
          self.carouselIsHidden = false;
        },1200)
      } else {
        self.lastSectionIsVisible = false;
        self.$refs.slick.goTo(num);
        setTimeout(function() {
          self.bottomSectionIsVisible = true;
          self.carouselIsVisible = true;
        },300)
      }
    },
    fromUpToCenter() {
      let self = this;
      self.upSectionIsVisible = false;
      self.centerSectionIsVisible = true;
      setTimeout(function() {
        self.slideIsHiddenDown4 = true;
      }, 300)
      setTimeout(function() {
        self.slideIsHiddenDown3 = true;
      }, 400)
      setTimeout(function() {
        self.slideIsHiddenDown2 = true;
      }, 500)
      setTimeout(function() {
        self.slideIsHiddenDown1 = true;
      }, 600)
    },
    fromCenterToBottom() {
      let self = this;
      self.centerTextIsVisible = false;
      self.centerSectionIsVisible = false;
      self.bottomSectionIsVisible = true;
      self.bottomTextIsVisible = true;
      self.shotIsVisible = true;
      self.$parent.hideLogo();
    },
    fromBottomToCenterRightAway() {
      let self = this;
      this.carouselIsFull = false;
      setTimeout(function() {
        self.slideIsHiddenUp1 = true;
      }, 500)
      setTimeout(function() {
        self.slideIsHiddenUp2 = true;
      }, 600)
      setTimeout(function() {
        self.slideIsHiddenUp3 = true;
      }, 700)
      setTimeout(function() {
        self.slideIsHiddenUp4 = true;
      }, 800)
      this.centerSectionIsVisible = true;
      this.bottomSectionIsVisible = false;
      this.bottomTextIsVisible = false;
      this.shotIsVisible = false;
      this.lastSectionIsVisible = false;
      setTimeout(function() {
        self.carouselIsVisible = true;
        self.centerTextIsVisible = true;
      }, 700)
    },
    fromBottomToLast() {
      let self = this;
      if (this.$refs.slick.currentSlide() < this.totalSlides - 1) {
        this.next();
      } else {
        this.bottomSectionIsVisible = false;
        this.carouselIsVisible = false;
        self.carouselIsHidden = true;
        setTimeout(function() {
          self.lastSectionIsVisible = true;
          self.carouselIsFull = true;
        },700)
        setTimeout(function() {
          self.$refs.slick.goTo(0);
        },800)
        setTimeout(function() {
          self.carouselIsHidden = false;
        },1200)
      }
    },
    fromBottomToCenter() {
      let self = this;
      if (this.$refs.slick.currentSlide() > 0) {
        this.prev();
      } else {
        this.carouselIsFull = false;
        this.centerSectionIsVisible = true;
        this.bottomSectionIsVisible = false;
        this.bottomTextIsVisible = false;
        this.shotIsVisible = false;
        setTimeout(function() {
          self.slideIsHiddenUp1 = true;
        }, 500)
        setTimeout(function() {
          self.slideIsHiddenUp2 = true;
        }, 600)
        setTimeout(function() {
          self.slideIsHiddenUp3 = true;
          self.carouselIsVisible = true;
          self.centerTextIsVisible = true;
        }, 700)
        setTimeout(function() {
          self.slideIsHiddenUp4 = true;
        }, 800)
        self.$parent.showLogo();
      }

    },
    pageDown() {
      let self = this;
      this.pointerNone = true;
      setTimeout(() => {
        self.pointerNone = false;
      }, 800);
      if (this.centerSectionIsVisible) {
        this.fromCenterToBottom();
      }
    },
    pageUp() {
      let self = this;
      this.pointerNone = true;
      setTimeout(() => {
        self.pointerNone = false;
      }, 800);
      if (this.bottomSectionIsVisible) {
        this.fromBottomToCenter();
      }
    },
  }
}
</script>
